import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _04bba268 = () => interopDefault(import('../pages/about_service/index.vue' /* webpackChunkName: "pages/about_service/index" */))
const _512f79c9 = () => interopDefault(import('../pages/analog-cisco/index.vue' /* webpackChunkName: "pages/analog-cisco/index" */))
const _466b7b34 = () => interopDefault(import('../pages/analog-skype/index.vue' /* webpackChunkName: "pages/analog-skype/index" */))
const _c6d1a574 = () => interopDefault(import('../pages/analog-teams/index.vue' /* webpackChunkName: "pages/analog-teams/index" */))
const _7b4436ce = () => interopDefault(import('../pages/analog-zoom/index.vue' /* webpackChunkName: "pages/analog-zoom/index" */))
const _278c0759 = () => interopDefault(import('../pages/application/index.vue' /* webpackChunkName: "pages/application/index" */))
const _1c4468ea = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _4f05b40a = () => interopDefault(import('../pages/audioconference/index.vue' /* webpackChunkName: "pages/audioconference/index" */))
const _8de38198 = () => interopDefault(import('../pages/bill/index.vue' /* webpackChunkName: "pages/bill/index" */))
const _d9025900 = () => interopDefault(import('../pages/contacts/index.vue' /* webpackChunkName: "pages/contacts/index" */))
const _77347b47 = () => interopDefault(import('../pages/faq_and_support/index.vue' /* webpackChunkName: "pages/faq_and_support/index" */))
const _19bbcb64 = () => interopDefault(import('../pages/functions/index.vue' /* webpackChunkName: "pages/functions/index" */))
const _0be01900 = () => interopDefault(import('../pages/metodichka/index.vue' /* webpackChunkName: "pages/metodichka/index" */))
const _1c6abc50 = () => interopDefault(import('../pages/partners/index.vue' /* webpackChunkName: "pages/partners/index" */))
const _29653a62 = () => interopDefault(import('../pages/policy-services/index.vue' /* webpackChunkName: "pages/policy-services/index" */))
const _7de68bc4 = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _188f82f6 = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _eeabc904 = () => interopDefault(import('../pages/sitemap/index.vue' /* webpackChunkName: "pages/sitemap/index" */))
const _76c86d66 = () => interopDefault(import('../pages/sitemap_old/index.vue' /* webpackChunkName: "pages/sitemap_old/index" */))
const _00168218 = () => interopDefault(import('../pages/tariffs/index.vue' /* webpackChunkName: "pages/tariffs/index" */))
const _152321f8 = () => interopDefault(import('../pages/user/index.vue' /* webpackChunkName: "pages/user/index" */))
const _7c50c10e = () => interopDefault(import('../pages/user-instruction/index.vue' /* webpackChunkName: "pages/user-instruction/index" */))
const _59930144 = () => interopDefault(import('../pages/user-registration/index.vue' /* webpackChunkName: "pages/user-registration/index" */))
const _736c4bc0 = () => interopDefault(import('../pages/videoconference/index.vue' /* webpackChunkName: "pages/videoconference/index" */))
const _14bdcdf9 = () => interopDefault(import('../pages/webconference/index.vue' /* webpackChunkName: "pages/webconference/index" */))
const _512f7ba2 = () => interopDefault(import('../pages/bill/fail/index.vue' /* webpackChunkName: "pages/bill/fail/index" */))
const _1980f228 = () => interopDefault(import('../pages/bill/success/index.vue' /* webpackChunkName: "pages/bill/success/index" */))
const _71245777 = () => interopDefault(import('../pages/clients/alfa_bank/index.vue' /* webpackChunkName: "pages/clients/alfa_bank/index" */))
const _53349746 = () => interopDefault(import('../pages/clients/amplua_broker/index.vue' /* webpackChunkName: "pages/clients/amplua_broker/index" */))
const _0ccf03a2 = () => interopDefault(import('../pages/clients/bittner_farma/index.vue' /* webpackChunkName: "pages/clients/bittner_farma/index" */))
const _3c77a5d7 = () => interopDefault(import('../pages/clients/completo/index.vue' /* webpackChunkName: "pages/clients/completo/index" */))
const _149e79e6 = () => interopDefault(import('../pages/clients/faberlic/index.vue' /* webpackChunkName: "pages/clients/faberlic/index" */))
const _38a196c7 = () => interopDefault(import('../pages/clients/fokus_media/index.vue' /* webpackChunkName: "pages/clients/fokus_media/index" */))
const _721e3ed8 = () => interopDefault(import('../pages/clients/foodik/index.vue' /* webpackChunkName: "pages/clients/foodik/index" */))
const _e5da1736 = () => interopDefault(import('../pages/clients/hansa/index.vue' /* webpackChunkName: "pages/clients/hansa/index" */))
const _be6121fa = () => interopDefault(import('../pages/clients/ilearney/index.vue' /* webpackChunkName: "pages/clients/ilearney/index" */))
const _b9b4c7f2 = () => interopDefault(import('../pages/clients/lenovo/index.vue' /* webpackChunkName: "pages/clients/lenovo/index" */))
const _2ff7c29d = () => interopDefault(import('../pages/clients/mgu/index.vue' /* webpackChunkName: "pages/clients/mgu/index" */))
const _bf15ef34 = () => interopDefault(import('../pages/clients/midea/index.vue' /* webpackChunkName: "pages/clients/midea/index" */))
const _3724cabe = () => interopDefault(import('../pages/clients/rudn/index.vue' /* webpackChunkName: "pages/clients/rudn/index" */))
const _84577b24 = () => interopDefault(import('../pages/clients/siemens/index.vue' /* webpackChunkName: "pages/clients/siemens/index" */))
const _2e816f55 = () => interopDefault(import('../pages/error/410/index.vue' /* webpackChunkName: "pages/error/410/index" */))
const _e2e4eacc = () => interopDefault(import('../pages/faq_and_support/system_test/index.vue' /* webpackChunkName: "pages/faq_and_support/system_test/index" */))
const _22ceda98 = () => interopDefault(import('../pages/payment/fail/index.vue' /* webpackChunkName: "pages/payment/fail/index" */))
const _ce29d0fa = () => interopDefault(import('../pages/payment/success/index.vue' /* webpackChunkName: "pages/payment/success/index" */))
const _a1815df0 = () => interopDefault(import('../pages/products/comdi/index.vue' /* webpackChunkName: "pages/products/comdi/index" */))
const _02ef3295 = () => interopDefault(import('../pages/products/messenger/index.vue' /* webpackChunkName: "pages/products/messenger/index" */))
const _18af7f92 = () => interopDefault(import('../pages/products/on-premise/index.vue' /* webpackChunkName: "pages/products/on-premise/index" */))
const _3363388c = () => interopDefault(import('../pages/products/rooms/index.vue' /* webpackChunkName: "pages/products/rooms/index" */))
const _13060c28 = () => interopDefault(import('../pages/products/webinar/index.vue' /* webpackChunkName: "pages/products/webinar/index" */))
const _5181b2fd = () => interopDefault(import('../pages/products/westudy/index.vue' /* webpackChunkName: "pages/products/westudy/index" */))
const _4a6f3970 = () => interopDefault(import('../pages/requests/many-participants/index.vue' /* webpackChunkName: "pages/requests/many-participants/index" */))
const _1dad97cc = () => interopDefault(import('../pages/requests/paid-webinars/index.vue' /* webpackChunkName: "pages/requests/paid-webinars/index" */))
const _34850700 = () => interopDefault(import('../pages/task/healthcare/index.vue' /* webpackChunkName: "pages/task/healthcare/index" */))
const _7e0502af = () => interopDefault(import('../pages/task/med/index.vue' /* webpackChunkName: "pages/task/med/index" */))
const _0fe8e273 = () => interopDefault(import('../pages/task/nmo/index.vue' /* webpackChunkName: "pages/task/nmo/index" */))
const _2ff9def2 = () => interopDefault(import('../pages/task/obuchenie_studentov/index.vue' /* webpackChunkName: "pages/task/obuchenie_studentov/index" */))
const _bc667b92 = () => interopDefault(import('../pages/task/ouz/index.vue' /* webpackChunkName: "pages/task/ouz/index" */))
const _682e8ee0 = () => interopDefault(import('../pages/task/pharma/index.vue' /* webpackChunkName: "pages/task/pharma/index" */))
const _660abb0d = () => interopDefault(import('../pages/articles/_item/index.vue' /* webpackChunkName: "pages/articles/_item/index" */))
const _9ad9f8c2 = () => interopDefault(import('../pages/billing-registration-custom/_item/index.vue' /* webpackChunkName: "pages/billing-registration-custom/_item/index" */))
const _5e432308 = () => interopDefault(import('../pages/email/_item/index.vue' /* webpackChunkName: "pages/email/_item/index" */))
const _bcf68c8a = () => interopDefault(import('../pages/tariffs/_item/index.vue' /* webpackChunkName: "pages/tariffs/_item/index" */))
const _05d1f19b = () => interopDefault(import('../pages/user/_item/index.vue' /* webpackChunkName: "pages/user/_item/index" */))
const _66d4e8cc = () => interopDefault(import('../pages/billing-registration-custom/_item/_slug/index.vue' /* webpackChunkName: "pages/billing-registration-custom/_item/_slug/index" */))
const _7a153cb6 = () => interopDefault(import('../pages/tariffs/_item/_slug/index.vue' /* webpackChunkName: "pages/tariffs/_item/_slug/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about_service",
    component: _04bba268,
    name: "about_service"
  }, {
    path: "/analog-cisco",
    component: _512f79c9,
    name: "analog-cisco"
  }, {
    path: "/analog-skype",
    component: _466b7b34,
    name: "analog-skype"
  }, {
    path: "/analog-teams",
    component: _c6d1a574,
    name: "analog-teams"
  }, {
    path: "/analog-zoom",
    component: _7b4436ce,
    name: "analog-zoom"
  }, {
    path: "/application",
    component: _278c0759,
    name: "application"
  }, {
    path: "/articles",
    component: _1c4468ea,
    name: "articles"
  }, {
    path: "/audioconference",
    component: _4f05b40a,
    name: "audioconference"
  }, {
    path: "/bill",
    component: _8de38198,
    name: "bill"
  }, {
    path: "/contacts",
    component: _d9025900,
    name: "contacts"
  }, {
    path: "/faq_and_support",
    component: _77347b47,
    name: "faq_and_support"
  }, {
    path: "/functions",
    component: _19bbcb64,
    name: "functions"
  }, {
    path: "/metodichka",
    component: _0be01900,
    name: "metodichka"
  }, {
    path: "/partners",
    component: _1c6abc50,
    name: "partners"
  }, {
    path: "/policy-services",
    component: _29653a62,
    name: "policy-services"
  }, {
    path: "/privacy-policy",
    component: _7de68bc4,
    name: "privacy-policy"
  }, {
    path: "/signup",
    component: _188f82f6,
    name: "signup"
  }, {
    path: "/sitemap",
    component: _eeabc904,
    name: "sitemap"
  }, {
    path: "/sitemap_old",
    component: _76c86d66,
    name: "sitemap_old"
  }, {
    path: "/tariffs",
    component: _00168218,
    name: "tariffs"
  }, {
    path: "/user",
    component: _152321f8,
    name: "user"
  }, {
    path: "/user-instruction",
    component: _7c50c10e,
    name: "user-instruction"
  }, {
    path: "/user-registration",
    component: _59930144,
    name: "user-registration"
  }, {
    path: "/videoconference",
    component: _736c4bc0,
    name: "videoconference"
  }, {
    path: "/webconference",
    component: _14bdcdf9,
    name: "webconference"
  }, {
    path: "/bill/fail",
    component: _512f7ba2,
    name: "bill-fail"
  }, {
    path: "/bill/success",
    component: _1980f228,
    name: "bill-success"
  }, {
    path: "/clients/alfa_bank",
    component: _71245777,
    name: "clients-alfa_bank"
  }, {
    path: "/clients/amplua_broker",
    component: _53349746,
    name: "clients-amplua_broker"
  }, {
    path: "/clients/bittner_farma",
    component: _0ccf03a2,
    name: "clients-bittner_farma"
  }, {
    path: "/clients/completo",
    component: _3c77a5d7,
    name: "clients-completo"
  }, {
    path: "/clients/faberlic",
    component: _149e79e6,
    name: "clients-faberlic"
  }, {
    path: "/clients/fokus_media",
    component: _38a196c7,
    name: "clients-fokus_media"
  }, {
    path: "/clients/foodik",
    component: _721e3ed8,
    name: "clients-foodik"
  }, {
    path: "/clients/hansa",
    component: _e5da1736,
    name: "clients-hansa"
  }, {
    path: "/clients/ilearney",
    component: _be6121fa,
    name: "clients-ilearney"
  }, {
    path: "/clients/lenovo",
    component: _b9b4c7f2,
    name: "clients-lenovo"
  }, {
    path: "/clients/mgu",
    component: _2ff7c29d,
    name: "clients-mgu"
  }, {
    path: "/clients/midea",
    component: _bf15ef34,
    name: "clients-midea"
  }, {
    path: "/clients/rudn",
    component: _3724cabe,
    name: "clients-rudn"
  }, {
    path: "/clients/siemens",
    component: _84577b24,
    name: "clients-siemens"
  }, {
    path: "/error/410",
    component: _2e816f55,
    name: "error-410"
  }, {
    path: "/faq_and_support/system_test",
    component: _e2e4eacc,
    name: "faq_and_support-system_test"
  }, {
    path: "/payment/fail",
    component: _22ceda98,
    name: "payment-fail"
  }, {
    path: "/payment/success",
    component: _ce29d0fa,
    name: "payment-success"
  }, {
    path: "/products/comdi",
    component: _a1815df0,
    name: "products-comdi"
  }, {
    path: "/products/messenger",
    component: _02ef3295,
    name: "products-messenger"
  }, {
    path: "/products/on-premise",
    component: _18af7f92,
    name: "products-on-premise"
  }, {
    path: "/products/rooms",
    component: _3363388c,
    name: "products-rooms"
  }, {
    path: "/products/webinar",
    component: _13060c28,
    name: "products-webinar"
  }, {
    path: "/products/westudy",
    component: _5181b2fd,
    name: "products-westudy"
  }, {
    path: "/requests/many-participants",
    component: _4a6f3970,
    name: "requests-many-participants"
  }, {
    path: "/requests/paid-webinars",
    component: _1dad97cc,
    name: "requests-paid-webinars"
  }, {
    path: "/task/healthcare",
    component: _34850700,
    name: "task-healthcare"
  }, {
    path: "/task/med",
    component: _7e0502af,
    name: "task-med"
  }, {
    path: "/task/nmo",
    component: _0fe8e273,
    name: "task-nmo"
  }, {
    path: "/task/obuchenie_studentov",
    component: _2ff9def2,
    name: "task-obuchenie_studentov"
  }, {
    path: "/task/ouz",
    component: _bc667b92,
    name: "task-ouz"
  }, {
    path: "/task/pharma",
    component: _682e8ee0,
    name: "task-pharma"
  }, {
    path: "/articles/:item",
    component: _660abb0d,
    name: "articles-item"
  }, {
    path: "/billing-registration-custom/:item",
    component: _9ad9f8c2,
    name: "billing-registration-custom-item"
  }, {
    path: "/email/:item",
    component: _5e432308,
    name: "email-item"
  }, {
    path: "/tariffs/:item",
    component: _bcf68c8a,
    name: "tariffs-item"
  }, {
    path: "/user/:item",
    component: _05d1f19b,
    name: "user-item"
  }, {
    path: "/billing-registration-custom/:item?/:slug",
    component: _66d4e8cc,
    name: "billing-registration-custom-item-slug"
  }, {
    path: "/tariffs/:item/:slug",
    component: _7a153cb6,
    name: "tariffs-item-slug"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/*",
    component: _2efaf336,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
